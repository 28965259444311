import * as React from "react"
import Link from 'gatsby-link'
import Seo from '../components/Seo'

import Layout from './../components/Layouts/Home'

// markup
const IndexPage = () => {
  return (
    <Layout>
      <div className="landing basic">
        <Seo meta="landing" />
        <h1 className="center h3">{`Dr Lingua's Learn Japanese Game`}</h1>
        <h2 className="center h4">{`Hiragana Katakana practice games`}</h2>
        <div class="center">
          <p>
            Proudly developed in Sydney by <a href="https://swoon.com.au">Adrian Gray</a>
            <br />
            Reach me on <a href="https://bsky.app/profile/blazedly.bsky.social">Bluesky</a></p>
          </div>
        <div className="logo-img center">
          <img
            src="/assets/images/dr-lingua.jpg"
            alt="Dr Lingua logo"
            width="842"
            height="596"
            data-pin-description="Dr Lingua logo"
          />
          <ul className="bullets">
            <li className="left">
              <span className="tick">{`✔`}</span>
              <h3 className="h5">{` Make study fun!`}</h3>
            </li>
            <li className="left">
              <span className="tick">{`✔`}</span>
              <h3 className="h5">{` Games to learn Japanese`}</h3>
            </li>
            <li className="left">
              <span className="tick">{`✔`}</span>
              <h3 className="h5">{` Hiragana Practice`}</h3>
            </li>
            <li className="left">
              <span className="tick">{`✔`}</span>
              <h3 className="h5">{` Katakana Practice`}</h3>
            </li>
            <li className="left">
              <span className="tick">{`✔`}</span>
              <h3 className="h5">{` Japanese learning games`}</h3>
            </li>
            <li className="left">
              <span className="tick">{`✔`}</span>
              <h3 className="h5">{` Mobile and iPad friendly`}</h3>
            </li>
          </ul>
        </div>

        <hr />

        <h3 className="center">{'Learning Japanese Hiragana and Katakana'}</h3>

        <h4 className="center">
          <Link to="/japanese/games/kana-grid/">{'Hiragana Katakana practice game - Kana Grid'}</Link>
        </h4>
        <div className="game-card">
          <div className="left">
            <div className="screenshot center">
              <Link to="/japanese/games/kana-grid/">
                <img
                  src="/assets/images/kana-grid-hiragana-game-romaji.jpg"
                  alt="Kana Grid Hiragana and Katakana game"
                  width="783px"
                  height="440px"
                  data-pin-description="Kana Grid, Hiragana and Katakana game."
                />
              </Link>
            </div>
            <div className="full-width center">
              <Link to="/japanese/games/kana-grid/" className="btn centre">
                {'Play Kana Grid'}
              </Link>
            </div>
          </div>
          <div className="right">
            <p>{`If you're brand new to Hiragana or Katakana this game helps familiarize you with Japanese characters. Pick the correct kana from a grid of similar kanas.`}</p>
            <p>{`Choose from Hiragana / Katakana / Romaji guide text, and a Hiragana or Katakana kana grid.`}</p>
            <p>{`Kana Grid runs best on tablets / computers.`}</p>
          </div>
        </div>
        <div className="clear" />
        <hr />
        <h4 className="center">
          <Link to="/japanese/games/kana-bento/">{'Katakana and Hiragana drag and drop - Kana Bento'}</Link>
        </h4>
        <div className="game-card">
          <div className="left">
            <div className="screenshot center">
              <Link to="/japanese/games/kana-bento/">
                <img
                  className="vertical-img"
                  src="/assets/images/kana-bento-screen.jpg"
                  alt="Kana Bento Hiragana drag and drop and Katakana drag and drop game"
                  width="332px"
                  height="443px"
                  data-pin-description="Kana Bento, Hiragana and Katakana game."
                />
              </Link>
            </div>
            <div className="btn-container full-width center">
              <Link to="/japanese/games/kana-bento/" className="btn centre">
                {'Play Kana Bento drag and drop'}
              </Link>
            </div>
            <div className="btn-container full-width center">
              <Link to="/japanese/games/drag-drop-hiragana-kana-bento/" className="btn centre">
                {'Play Hiragana drag and drop'}
              </Link>
            </div>
            <div className="btn-container full-width center">
              <Link to="/japanese/games/drag-drop-katakana-kana-bento/" className="btn centre">
                {'Play Katakana drag and drop'}
              </Link>
            </div>
          </div>
          <div className="right">
            <p>{`Practice Hiragana and Katakana with this kana game. Place shuffled kanas into their correct position on a kana chart. Choose from Romaji, Hiragana, or Katakana, kana sets on the chart.
              Pick from the same options for kanas to drag and drop on to the chart.`}</p>
            <p>{`Match the whole chart, or only match certain columns. Improve  your speed at Hiragana and Katakana recognition and matching.`}</p>
            <p>{`Kana Bento runs on mobiles / tablets / computers.`}</p>
          </div>
        </div>
        <div className="clear" />
      </div>
    </Layout>
  )
}

export default IndexPage
